import { useState } from "react";
import AddItemForm from "../components/AddItemForm";
import StripeContainer from "../components/StripeContainer";

const Stripe = () => {
  const [type, setType] = useState("form");
  const [item, setItem] = useState("");
  const handleAddItem = (item) => {
    setItem(item);
    setType("stripe");
  };

  return (
    <>
      {type === "form" && <AddItemForm onAddItem={handleAddItem} />}
      {type === "stripe" && item && (
        <StripeContainer storedAmount={+item.price * 100} item={item} />
      )}
    </>
  );
};

export default Stripe;
