import { useState } from "react";

const AddItemForm = ({ onAddItem }) => {
  const [formData, setFormData] = useState({
    name: "Tarmac",
    price: "",
  });
  const handleAddItem = (e) => {
    e.preventDefault();
    if (formData.name && formData.price) {
      onAddItem(formData);
    } else {
      alert("Please fill out all fields!");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div style={styles.formCard}>
      <form style={styles.form} onSubmit={handleAddItem}>
        <h2 style={styles.formHeading}>Add Record Item</h2>
        <label style={styles.label}>Record Name</label>
        <input
          type="text"
          name="name"
          disabled
          placeholder="Enter Record name"
          value={formData.name}
          onChange={handleInputChange}
          style={styles.input}
        />
        <label style={styles.label}>Price (USD)</label>
        <input
          type="number"
          name="price"
          placeholder="Enter price"
          value={formData.price}
          onChange={handleInputChange}
          style={styles.input}
        />
        <button type="submit" style={styles.submitButton}>
          Proceed to bill
        </button>
      </form>
    </div>
  );
};

const styles = {
  formCard: {
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "20px",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
    margin: "20px auto",
    width: "90%",
    maxWidth: "600px",
  },
  form: {
    textAlign: "left",
  },
  formHeading: {
    color: "#4A90E2",
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "15px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "5px",
    display: "block",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "15px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    fontSize: "16px",
    boxSizing: "border-box",
  },
  submitButton: {
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    padding: "12px",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "100%",
    boxShadow: "0 4px 10px rgba(40, 167, 69, 0.4)",
    transition: "all 0.3s ease",
  },

  totalAmount: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#4A90E2",
    marginBottom: "15px",
  },
  checkoutButton: {
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    padding: "12px 25px",
    borderRadius: "25px",
    cursor: "pointer",
    fontSize: "18px",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 10px rgba(40, 167, 69, 0.4)",
  },
};

export default AddItemForm;
