import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripePaymentSuccess from "./StripePaymentSuccess";
import { createItem } from "../services/createItem";

export default function StripeCheckoutForm({ dpmCheckerLink, item }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [intentId, setIntentId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    const { status } = paymentIntent;

    if (status === "succeeded") {
      setIntentId(paymentIntent.id);
      const response = await createItem(item);
      console.log(response);
      setStatus(status);

      setIsSuccess(true);
    } else {
      setMessage(error.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "accordion",
  };

  return (
    <>
      {!isSuccess ? (
        <>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
          </form>
          <div id="dpm-annotation">
            <p>
              Payment methods are dynamically displayed based on customer
              location, order amount, and currency.&nbsp;
              <a
                href={dpmCheckerLink}
                target="_blank"
                rel="noopener noreferrer"
                id="dpm-integration-checker"
              >
                Preview payment methods by transaction
              </a>
            </p>
          </div>
        </>
      ) : (
        <StripePaymentSuccess intentId={intentId} status={status} />
      )}
    </>
  );
}
