import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./style.css";

import HomePage from "./pages/home";
import Stripe from "./pages/stripe";

function App() {
  return (
    <div style={styles.container}>
      <h1 style={styles.mainHeading}>POS Manager</h1>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/stripe" element={<Stripe />} />
      </Routes>
    </div>
  );
}

const styles = {
  container: {
    width: "100vw",
    minHeight: "100vh",
    textAlign: "center",
    fontFamily: `'Poppins', sans-serif`,
    padding: "20px",
    background: "linear-gradient(to bottom, #f5f7fa, #c3cfe2)",
    boxSizing: "border-box",
  },
  mainHeading: {
    fontSize: "32px",
    color: "#4A90E2",
    marginBottom: "20px",
  },
  addButton: {
    backgroundColor: "#4A90E2",
    color: "#fff",
    border: "none",
    padding: "12px 25px",
    borderRadius: "25px",
    cursor: "pointer",
    fontSize: "18px",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 10px rgba(74, 144, 226, 0.4)",
    marginBottom: "20px",
  },
  formCard: {
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: "20px",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
    margin: "20px auto",
    width: "90%",
    maxWidth: "600px",
  },
  form: {
    textAlign: "left",
  },
  formHeading: {
    color: "#4A90E2",
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "15px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "5px",
    display: "block",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "15px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    fontSize: "16px",
    boxSizing: "border-box",
  },
  submitButton: {
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    padding: "12px",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "100%",
    boxShadow: "0 4px 10px rgba(40, 167, 69, 0.4)",
    transition: "all 0.3s ease",
  },
  tableContainer: {
    marginTop: "30px",
    textAlign: "center",
    width: "100%",
    padding: "0 10px",
    boxSizing: "border-box",
  },
  tableHeading: {
    fontSize: "22px",
    color: "#4A90E2",
    marginBottom: "15px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#fff",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    overflow: "hidden",
  },
  tableHeader: {
    backgroundColor: "#4A90E2",
    color: "#fff",
    padding: "12px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableCell: {
    padding: "12px",
    borderBottom: "1px solid #ddd",
    textAlign: "center",
    fontSize: "15px",
  },
  checkoutSection: {
    marginTop: "30px",
  },
  totalAmount: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#4A90E2",
    marginBottom: "15px",
  },
  checkoutButton: {
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    padding: "12px 25px",
    borderRadius: "25px",
    cursor: "pointer",
    fontSize: "18px",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 10px rgba(40, 167, 69, 0.4)",
  },
};

export default App;
