import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckoutForm from "./StripeCheckOut";
import { BASE_URL, STRIPE_PUBLIC_KEY } from "../constants/environment";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function StripeContainer({ storedAmount, item }) {
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");

  useEffect(() => {
    const amount = parseFloat(storedAmount);

    const paymentResponse = fetch(`${BASE_URL}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setDpmCheckerLink(data.dpmCheckerLink);
      })
      .catch((error) => {
        alert(`Error: ${error.message}`);
      });
  }, [storedAmount, item]);

  const appearance = {
    theme: "stripe",
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  return (
    <>
      {clientSecret && (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          <StripeCheckoutForm
            clientSecret={clientSecret}
            dpmCheckerLink={dpmCheckerLink}
            item={item}
          />
        </Elements>
      )}
    </>
  );
}
