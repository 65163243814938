import axiosInstance from "../interceptor/axiosInstance";

export const getItems = async () => {
  try {
    const response = await axiosInstance.get("/api/showItems");
    return response;
  } catch (error) {
    alert(error);
  }
};
